import { mergeClasses, absoluteUrl } from "../lib/_helper";
import swal from "sweetalert";
import { useEffect, useState } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { CheckIcon, CheckCircleIcon } from '@heroicons/react/outline'
import CustomDropDown from "./CustomDropDown";

export const gicsSectors = [
  "Energy",
  "Materials",
  "Industrials",
  "Consumer Discretionary",
  "Consumer Staples",
  "Health Care",
  "Financials",
  "Information Technology",
  "Communication Services",
  "Utilities",
  "Real Estate"
];

export const transactionTypes = [
  "Acquisition",
  "Merger",
  "Separation",
  "Divestment",
  "Carve-out",
  "Integration",
  "Joint Venture",
  "Recapitalisation",
  "Restructuring",
  "Bankruptcy and Insolvency",
  "Management Buyout (MBO)",
  "Leveraged Buyout (LBO)"
];

const AppState = {
  EnterData: 'EnterData',
  SeeResults: 'SeeResults',
  SeeStats: 'SeeStats',
};

const Progress = ({state, setState}) => {
  const ProgressState = {
    Complete: 'complete',
    Current: 'current',
    Upcoming: 'upcoming',
  };

  const steps = [
    { id: "Step 1", stateRef: AppState.EnterData, name: 'Enter fields', status: (state == AppState.EnterData) ? ProgressState.Current : ProgressState.Complete },
    { id: "Step 2", stateRef: AppState.SeeResults, name: 'See Results & Make Selection', status: (state == AppState.SeeResults) ? ProgressState.Current : (state == AppState.EnterData ? ProgressState.Upcoming : ProgressState.Complete) },
    { id: "Step 3", stateRef: AppState.SeeStats, name: 'Leaderboard', status: (state == AppState.SeeStats) ? ProgressState.Current : ProgressState.Upcoming },
  ]
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === ProgressState.Complete && (
              <a
                className="group flex flex-col cursor-pointer border-l-4 border-cto_primary_purple py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                onClick={() => setState(step.stateRef)}
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Current && (
              <a
                className="flex flex-col border-l-4 cursor-default border-cto_primary_orange animate-pulse py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
                aria-current="step"
              >
                <span className="text-sm font-medium text-cto_primary_purple">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
            {step.status === ProgressState.Upcoming && (
              <a
                className="group flex flex-col cursor-not-allowed border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 hover:opacity-50"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

const ProjectNameSuggestionCard = ({name, description, alternatives, isChecked, setChecked, disabled}) => {
  return (
    <li
      className={
        mergeClasses(
          "overflow-hidden rounded-xl border-2",
          isChecked ? " border-cto_primary_orange/50" : "border-gray-200",
          disabled ? "opacity-50" : ""
      )}
      onClick={() => {!disabled && setChecked()}}
    >
      <div className={
        mergeClasses(
          "flex justify-between gap-x-4 border-b border-gray-900/5 p-6",
          isChecked ? "bg-cto_primary_orange/25" : "bg-gray-50" 
        )}>
        <div className="text-sm font-medium leading-6 text-gray-900">Project {name}</div>
        <input
          checked={isChecked}
          onChange={setChecked}
          type="checkbox"
          disabled={disabled}
          className="h-6 w-6 rounded-full border-gray-300 border text-cto_primary_orange focus:ring-cto_primary_orange"
        />
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="x-4 py-3">
          <dd className="text-gray-700">{description}</dd>
        </div>
        {(alternatives && alternatives.length > 0) && (
          <div>
            <div className="x-4 pt-3">
              <dt className="text-gray-900 underline">International variants:</dt>
            </div>
            {alternatives.map((alt, index) => {
              return (
                <div key={index} className="flex justify-between x-4 py-3">
                  <dt className="text-gray-500">{alt.name_variation}</dt>
                  <dt className="text-gray-500">({alt.language})</dt>
                </div>
              )
            })}
          </div>
        )}
      </dl>
    </li>
  )
}

const BarChart = ({ data }) => {
  console.dir({data})
  const sortedData = data.sort((a, b) => b.tally - a.tally);
  const maxValue = Math.max(...data.map(item => item.tally));

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Top 10 Leading Name-Gen Companies!</h2>
      <table className="w-full table-fixed">
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="align-middle">
              <td className="w-1/4 pr-4 text-sm text-gray-600">{item.name}</td>
              <td className="w-3/4">
                <div
                  className={
                    mergeClasses(
                      "h-6 bg-cto_primary_orange rounded-full",
                      item.selected ? "bg-cto_primary_purple" : "bg-cto_primary_orange"
                    )
                  }
                  style={{ width: `${(item.tally / maxValue) * 100}%` }}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const NameGen = ({ blok, shared }) => {
  //Stage 1
  const defaultSectorText = "Pick Sector";
  const defaultTransactionTypeText = "Pick Transaction Type";

  const [appState, setAppState] = useState(AppState.EnterData);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [firms, setFirms] = useState(null);
  const [firmNames, setFirmNames] = useState(null);
  const [firmName, setFirmName] = useState("");
  const [userName, setUserName] = useState("");
  const [selectedGicsSector, setSelectedGicsSector] = useState(defaultSectorText);
  const [selectedTransactionType, setSelectedTransactionType] = useState(defaultTransactionTypeText);
  const [transactionCharacteristics, setTransactionCharacteristics] = useState("");

  useEffect(() => {
    fetch(absoluteUrl("/api/nom-de-guerre/getFirmNames"), {
      method: "GET",
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Server responded with a failure.")
      }
      return response.json();
    }).then((data) => {
      const _firms = data.firms;
      setFirms(_firms);
    });
  }, [])

  useEffect(() => {
    setFirmNames(firms?.map(firm => firm.name) ?? null);
  }, [firms])

  let formComplete = true;
  formComplete &= (selectedGicsSector != defaultSectorText);
  formComplete &= (selectedTransactionType != defaultTransactionTypeText);
  formComplete &= (transactionCharacteristics != "");
  formComplete &= (firmName != "");

  const onDataSubmissionClick = () => {
    if (!formComplete) {
      swal(
        "Oops!", 
        "Please fill in all of the fields to proceed.", 
        "error"
      );
      return;
    }

    const formData = new FormData()
    formData.append("sector", selectedGicsSector)
    formData.append("transactionType", selectedTransactionType)
    formData.append("transactionCharacteristics", transactionCharacteristics)
    formData.append("firmName", firmName)
    formData.append("userName", userName)

    setSubmissionInProgress(true);
    fetch(absoluteUrl("/api/nom-de-guerre"), {
      body: formData,
      method: "POST",
    })
    .then(async (response) => {
      const jsonResponse = await response.json()
      setGeneratedNames(jsonResponse.generatedNames.options)
      setAppState(AppState.SeeResults);
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "If you're still interested in the content, please send an email to connect@ctolabs.com.au", "error")
    })
    .finally(() => {
      setSubmissionInProgress(false);
    })
  }

  //Stage 2
  const [generatedNames, setGeneratedNames] = useState([])
  const [selectedName, setSelectedName] = useState();

  const onNameSelectionClick = () => {
    if (!selectedName) {
      swal(
        "Oops!", 
        "Please select a name to proceed.", 
        "error"
      );
      return;
    }

    const formData = new FormData()
    formData.append("firmName", firmName)
    formData.append("selectedName", selectedName)
    formData.append("transactionType", selectedTransactionType)
    formData.append("sector", selectedGicsSector)
    formData.append("userName", userName ?? "")

    fetch(absoluteUrl("/api/nom-de-guerre/confirmSelection"), {
      body: formData,
      method: "POST",
    })
    .then(async (res) => {
      console.dir({res})
      console.log("Operation successful")
    })
    .catch((error) => {
      console.error({error})
      swal("Oops, something went wrong!", "There was an error when trying to save your submission...", "error")
    })
    .finally(() => {
    })

    setAppState(AppState.SeeStats)
  }

  const regenerateNames = () => {
    onDataSubmissionClick();
  }

  //Stage 3
  const [scoreboard, setScoreboard] = useState(null);

  const data = [
    { label: 'Five V', value: 48 },
    { label: 'Adamantem', value: 32 },
    { label: 'Quadrant PE', value: 60 },
    { label: 'Macquarie', value: 72 },
    { label: 'EQT', value: 28 },
    { label: 'GIA', value: 12 },
    { label: 'FPE', value: 6 },

    { label: 'oOh', value: 2 },
    { label: 'AWS', value: 3 },
    { label: 'CTO Labs', value: 1 },
  ];

  useEffect(() => {
    if (appState === AppState.SeeStats) {
      let _scoreboard = firms ?? [];
      let pickedExistingCompany;
      for (const score of _scoreboard) {
        if (score.name == firmName) {
          score.tally += 1;
          score.selected = true;
          pickedExistingCompany = true;
          break;
        }
      }

      if (!pickedExistingCompany) {
        _scoreboard.push({name: firmName, tally: 1, selected: true})
      }
      setScoreboard(_scoreboard);
    }
  }, [appState])

  

  return (
    <div className="bg-cto_secondary_light_warm_grey">
      <div className="max-w-7xl mx-auto p-4">
        <div className="shadow-lg space-y-6 p-8 bg-white rounded-xl">
          <div className="pb-4">
            <Progress state={appState} setState={(val) => setAppState(val)}/>
          </div>
          <div className="flex space-x-3">

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              {/* <!-- Background circle, simulating the div with bg-cto_primary_purple and rounded-full --> */}
              <circle cx="16" cy="16" r="16" fill="#443087"/> 

              {/* <!-- The original SVG content --> */}
              <svg x="5" y="5" fill="white" viewBox="0 0 24 24" width="22" height="22">
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </svg>


            {/* <div className="bg-cto_primary_purple rounded-full w-8 h-8 flex items-center">
              <svg fill="white" viewBox="0 0 24 24" className="h-[22px] w-[22px] mx-auto">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </div> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              {/* <!-- Background circle, simulating the div with bg-cto_primary_purple and rounded-full --> */}
              <circle cx="16" cy="16" r="16" fill="#443087"/>
              
              {/* <!-- The original SVG content translated from the inner div --> */}
              <svg x="6" y="6" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20">
                <path 
                  d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                />
              </svg>
            </svg>
            
            {/* <div className="bg-cto_primary_purple rounded-full w-8 h-8 flex items-center">
              <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-5 w-5 mx-auto"><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"/></svg>
            </div> */}





            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              {/* <!-- Background circle, simulating the div with bg-cto_primary_purple and rounded-full --> */}
              <circle cx="16" cy="16" r="16" fill="#443087"/>
              
              {/* <!-- The original SVG content translated from the inner div --> */}
              <svg x="5" y="5" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="22" height="22">
                <path 
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                />
              </svg>
            </svg>

            {/* <div className="bg-cto_primary_purple rounded-full w-8 h-8 flex items-center">
              <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="h-[22px] w-[22px] mx-auto">
                <path 
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                />
              </svg>
            </div> */}
          </div>
          {appState == AppState.EnterData && (
            <>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%]">
                  <div className={"space-y-4 md:space-y-0 md:flex md:justify-between md:space-x-4 max-w-[640px]"}>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-tex flex">Pick the industry sector*: {(selectedGicsSector != defaultSectorText) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <select 
                          className={mergeClasses("border-gray-300 w-full px-4 py-2 placeholder:text-gray-400 text-cto_secondary_dark_grey rounded-md focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                            submissionInProgress ? "bg-gray-100" : ""
                          )}
                          onChange={(e) => setSelectedGicsSector(e.target.value)}
                          disabled={submissionInProgress}
                          value={selectedGicsSector}
                          >
                            <option disabled>{defaultSectorText}</option>
                            {gicsSectors.map((sector) => {return (<option key={sector}>{sector}</option>)})}
                        </select>
                      </div>
                    </div>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text flex">Pick the type of transaction: {(selectedTransactionType != defaultTransactionTypeText) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <select 
                          className={mergeClasses("border-gray-300 w-full px-4 py-2 placeholder:text-gray-400 text-cto_secondary_dark_grey rounded-md focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                            submissionInProgress ? "bg-gray-100" : ""
                          )}
                          onChange={(e) => setSelectedTransactionType(e.target.value)}
                          disabled={submissionInProgress}
                          value={selectedTransactionType}
                        >
                          <option disabled>{defaultTransactionTypeText}</option>
                          {transactionTypes.map((sector) => {return (<option key={sector}>{sector}</option>)})}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%] min-w-[312px]">
                  <div className="label">
                    <span className="label-text flex">Characteristics of the transaction: {(transactionCharacteristics.length > 0) && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      value={transactionCharacteristics}
                      disabled={submissionInProgress}
                      onChange={(e) => setTransactionCharacteristics(e.target.value)}
                      className={mergeClasses("block w-full max-w-[640px] rounded-md border-0 px-4 py-2 text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                        submissionInProgress ? "bg-gray-100" : ""
                      )}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="block font-medium leading-6 text-cto_secondary_dark_grey mr-[20%]">
                  <div className={"space-y-4 md:space-y-0 md:flex md:justify-between md:space-x-4 max-w-[640px]"}>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text flex">Your company: {(firmName != "") && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <CustomDropDown
                          value={firmName}
                          setValue={setFirmName}
                          disabled={submissionInProgress}
                          options={firmNames}
                        />
                      </div>
                    </div>
                    <div className="w-[312px]">
                      <div className="label">
                        <span className="label-text flex z-0"><span className="z-0">Your name:</span><span className="label-text text-sm text-gray-500 mt-0.5 z-0">&nbsp; (optional)</span> {(userName != "") && <CheckCircleIcon className="h-5 w-5 mt-0.5 mx-2 text-green-600" aria-hidden="true" />}</span>
                      </div>
                      <div className="mt-2">
                        <input
                          value={userName}
                          disabled={submissionInProgress}
                          onChange={(e) => setUserName(e.target.value)}
                          className={mergeClasses("block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6",
                          submissionInProgress ? "bg-gray-100" : ""
                        )}
                        placeholder="John Appleseed"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full ",
                      formComplete
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                      submissionInProgress
                        ? "animate-pulse"
                        : ""
                    )}
                  onClick={onDataSubmissionClick}
                  disabled={submissionInProgress}
                >
                  {submissionInProgress 
                    ? (<div className="flex">
                      <svg className="animate-spin -ml-1 mr-3 h-4 w-4 mt-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </div>)
                    : (<>Generate Names</>)
                  }
                </button>
              </div>
              <div>
                <span className="text-gray-500">*Industry sectors are based off of GICS, read more <a className="underline" target="_blank" href="https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard">here</a>.</span>
              </div>
            </>
          )}
          {appState == AppState.SeeResults && (
            <>
              <div className="p-4 lg:p-8">
                <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                  {generatedNames.map((option, index) => ( 
                    <ProjectNameSuggestionCard key={index} name={option.name} description={option.description} alternatives={option.language_variations} isChecked={option.name == selectedName} setChecked={() => setSelectedName(option.name)} disabled={submissionInProgress}/>
                  ))}
                </ul>
              </div>
              <div className="flex space-x-4">
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full ",
                      selectedName
                        ? "bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                        : "bg-cto_secondary_dark_grey hover:bg-opacity-50 cursor-not-allowed",
                    )}
                  onClick={onNameSelectionClick}
                  disabled={submissionInProgress}
                >
                  Select name
                </button>
                <button 
                  className={
                    mergeClasses(
                      "inline-flex text-cto_primary_orange bg-white ring-cto_primary_orange ring-1 ring-inset font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full hover:ring-cto_primary_purple hover:text-cto_primary_purple cursor-pointer"
                    )}
                  onClick={regenerateNames}
                  disabled={submissionInProgress}
                >
                  Regenerate names
                </button>
              </div>
            </>
          )}
          {appState == AppState.SeeStats && (
            <>
              {scoreboard && (<div className="py-10">
                  <BarChart data={scoreboard} />
                </div>)
              }
              <div>
                <button 
                  className="inline-flex text-white font-bold max-w-[360px] w-4/5 items-center justify-center px-18 py-3 border border-transparent text-base rounded-full bg-cto_primary_orange hover:bg-cto_primary_purple cursor-pointer"
                  onClick={() => setAppState(AppState.EnterData)}
                >
                  Take me back to the beginning
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameGen;
