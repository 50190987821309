import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import { mergeClasses } from '../lib/_helper';
import { useState, useEffect, useRef } from 'react';

const SelectInput = (props) => {
  const [inputValue, setInputValue] = useState(props.value ?? "");
  const [userAddedOption, setUserAddedOption] = useState(null); // Only one user-added option
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selected, setSelected] = useState(!!props.value);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // For keyboard navigation
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Controls dropdown visibility
  const [showAddOption, setShowAddOption] = useState(false);
  const optionRefs = useRef([]); // Array of refs to track each option's DOM element
  const containerRef = useRef(null); // Ref for the overall component container
  const inputRef = useRef();
  
  useEffect(() => {
    // Combine props.options and the single userAddedOption if it exists
    const combinedOptions = [...props.options ?? [], ...(userAddedOption ? [userAddedOption] : [])];
    const _showAddOptionValue = inputValue && !combinedOptions.find(op => op.toLowerCase() === inputValue.toLowerCase());
    setShowAddOption(_showAddOptionValue)

    if (inputValue === '') {
      setFilteredOptions(combinedOptions);
      setHighlightedIndex(-1); // Reset highlight when input is cleared
    } else {
      const filtered = combinedOptions.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredOptions(filtered);
      const _highlightedIndex = _showAddOptionValue ? filtered.length : 0
      setHighlightedIndex(_highlightedIndex); // Reset highlight when filtering changes
    }
  }, [inputValue, userAddedOption, props.options]);

  useEffect(() => {
    if (!isDropdownOpen && selected == false) {
      setInputValue('')
    }

  }, [isDropdownOpen, selected])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Attach listener to detect clicks outside the component
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const addOption = () => {
    setUserAddedOption(inputValue);
    selectOption(inputValue);
  };

  // Handle option selection
  const selectOption = (option) => {
    setInputValue(option);
    setSelected(true);
    props.setValue(option);
    setFilteredOptions([]);
    setHighlightedIndex(filteredOptions.length); // Reset highlight after selection
    setIsDropdownOpen(false); // Close dropdown on selection
  };

  // Clear the selected option and remove the user-added option if it matches the current input
  const clearSelection = () => {
    setInputValue('');
    setSelected(false);
    props.setValue('');

    if (inputValue === userAddedOption) {
      setUserAddedOption(null); // Clear the user-added option if it was selected
    }
  };

  const getNumOptions = () => filteredOptions.length + (showAddOption ? 1 : 0);

  // Scroll to the highlighted option
  const scrollToHighlightedOption = (index) => {
    if (optionRefs.current[index]) {
      optionRefs.current[index].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  };

  // Keyboard event handler
  const handleKeyDown = (e) => {
    let numOptions = getNumOptions();
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % numOptions;
        scrollToHighlightedOption(nextIndex);
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => {
        const nextIndex = (prevIndex > 0 ? prevIndex : numOptions) - 1;
        scrollToHighlightedOption(nextIndex);
        return nextIndex;
      });
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      // Check if the highlighted index is the "Add" option
      if (highlightedIndex === filteredOptions.length && showAddOption) {
        addOption();
      } else {
        selectOption(filteredOptions[highlightedIndex]);
      }
    }
  };

  const enforceInput = (input) => {
    const regex = /[^A-Za-z0-9_\-\.\ ]/g;
    input.target.value = input.target.value.replace(regex, ''); 
  }

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative">
        <input
          id="input-select"
          onInput={(input) => enforceInput(input)}
          maxLength={32}
          type="text"
          disabled={props.disabled}
          className={mergeClasses(
            'block rounded-md border-0 px-4 py-2 w-full max-w-[320px] text-cto_secondary_dark_grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cto_primary_purple sm:text-sm sm:leading-6',
            selected ? 'bg-gray-100 text-cto_secondary_dark_grey' : 'text-gray-400',
            props.disabled ? "bg-gray-100" : ""
          )}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onClick={() => setIsDropdownOpen(!props.disabled)} // Reopen dropdown on click
          onKeyDown={handleKeyDown} // Attach the keyboard event handler
          readOnly={selected}
          ref={inputRef}
          placeholder="Search or create..."
        />

        {(selected && !props.disabled) && (
          <button
            onClick={clearSelection}
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}

        {!selected && isDropdownOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-scroll max-h-[170px] focus:outline-none sm:text-sm">
            {(showAddOption || !inputValue) &&
              (
                <div
                  onClick={() => showAddOption ? addOption() : inputRef.current.focus()}
                  className={mergeClasses(
                    'flex underline select-none relative py-2 pl-3 pr-9 text-cto_primary_purple',
                    highlightedIndex === filteredOptions.length ? 'bg-indigo-600 text-white' : '',
                    inputValue ? "hover:bg-gray-200 cursor-pointer" : ""

                  )}
                  ref={(el) => optionRefs.current[filteredOptions.length] = el} // Add ref for "Add" option
                >
                  {showAddOption &&
                    <>
                        <PlusIcon className="z-80 h-4 w-4 mt-0.5 mx-2 text-green-600" aria-hidden="true" />
                        Add &quot;{inputValue}&quot; as a company.
                      </>
                  }

                  {!inputValue &&
                    <>Type to enter a new option...</>
                  }
                </div>
                )
              }

            {filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => selectOption(option)}
                className={mergeClasses(
                  'cursor-pointer select-none relative py-2 pl-3 pr-9 text-cto_secondary_dark_grey',
                  highlightedIndex === index
                    ? 'bg-indigo-600 text-white'
                    : 'hover:bg-gray-200'
                )}
                ref={(el) => optionRefs.current[index] = el} // Add ref to each option
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectInput;
