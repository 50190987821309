import ArticleCard from "../../blog-sections/ArticleCard";

const ListAllCareers = ({ blok, shared }) => {
  const careers = shared.careers;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex justify-center">
        <div className="max-w-7xl w-full overflow-x-auto shadow-md sm:rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-cto_secondary_light_warm_grey">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Job Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Location
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Department
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Employment Type
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {careers.map((career) => (
                    <tr className="transition-transform transform hover:scale-100 hover:translate-x-4 overflow-visible" key={career.email}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-cto_primary_purple"><a href="#">{career.name}</a></td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{career.location ?? "Sydney"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{career.department ?? "M&A"}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{career.employementType ?? "Permanent / Full Time"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="max-w-7xl grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3 py-12">
          {careers.map((career, i) => {
            console.log(career.name)
            return (
              <div key={i}>
                <ArticleCard blok={career} useDefaultImage={true} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="flex justify-center">
  //     <div className="max-w-7xl px-4 grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-3 py-12">
  //       {careers.map((career, i) => {
  //         console.log(career.name)
  //         return (
  //           <div key={i}>
  //             <ArticleCard blok={career} useDefaultImage={true} />
  //           </div>
  //         )
  //       })}
  //     </div>
  //   </div>
  // )
}

export default ListAllCareers;
